//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      cityList: [],
      areaId: [],
    };
  },
  props: {
    showCity: {
      type: Boolean,
      default: !1,
    },
    //已经选择的所有的地区
    alreadyTotalCity: {
      default: [],
    },
  },
  created() {
    let alreadyTotalCity = this.alreadyTotalCity;
    this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then((res) => {
      if (res.code == 0) {
        let list = res.result.list;
        for (let i in list) {
          if (list[i].name == '天津市') list[i]._child = list[i]._child[0]._child;
          if (list[i].name == '北京市') list[i]._child = list[i]._child[0]._child;
          list[i].checked = !1;
          list[i].border = !1;
          let _child = list[i]._child || [];
          list[i]._child_len = _child.length;
          for (let y in list[i]._child) {
            list[i]._child[y].checked = !1;
          }
        }
        if (alreadyTotalCity.length) {
          for (let i in alreadyTotalCity) {
            for (let y in list) {
              if (alreadyTotalCity[i].id == list[y].id) {
                list[y].disabled = !0;
                list[y].checked = !0;
              }
            }
          }
        }
        this.cityList = list;
      }
    });
  },
  methods: {
    //选择省
    selectProvince(e, i) {
      let cityList = this.cityList;
      let city = cityList[i]._child || [];
      let areaId = this.areaId;
      if (e.checked) {
        for (let i in city) city[i].checked = !0;
        cityList[i].num = city.length;
        areaId.push(cityList[i]);
      } else {
        for (let i in city) city[i].checked = !1;
        cityList[i].num = 0;
        let arr = areaId.filter((i) => i.id != e.id);
        areaId = arr;
      }
      this.areaId = areaId;
    },
    //选择市
    selectCity(e, i, y) {
      let city = this.cityList[i]._child;
      let areaId = this.areaId;
      let filterCity = city.filter((i) => i.checked);
      if (filterCity.length) {
        this.cityList[i].checked = !0;
        this.cityList[i].num = filterCity.length;
      } else {
        this.cityList[i].num = 0;
        this.cityList[i].checked = !1;
      }
      areaId.push(this.cityList[i]);
      //根据id去重
      let arr = [];
      let obj = {};
      for (let i = 0; i < areaId.length; i++) {
        if (!obj[areaId[i].id]) {
          arr.push(areaId[i]);
          obj[areaId[i].id] = true;
        }
      }
      //选择市级城市为0时去除省级数据
      if (!filterCity.length) arr = arr.filter((item) => item.id != this.cityList[i].id);
      this.areaId = arr;
    },
    mouseover(i) {
      if (!this.cityList[i].disabled) {
        this.cityList[i].border = !0;
      }
    },
    mouseout(i) {
      this.cityList[i].border = !1;
    },
    selectArea() {
      this.$emit('getArea', this.areaId);
      this.cancelCity();
    },
    cancelCity() {
      this.$emit('closeCity');
    },
    handleClose() {
      this.$emit('closeCity'); 
    },
  },
};
