//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import City from './components/city';
import Preservation from '@/components/preservation';

export default {
  components: {
    City,
    Preservation,
  },
  data() {
    const verify_freight_num = (rule, value, callback) => {
      if (!/^[\d|\.]*$/.test(value)) {
        callback(new Error('必须为数字'));
      }else{
         callback();
      }
    };
    return {
      showCity: !1,
      choosefirst: '0',
      form: {
        name: '',
        displayorder: '',
        pay_type: 1,
        preferential_num: '',
        preferential_type: '0',
        free_shipping: 2,
        is_select: 1,
        is_able: 1,
        areas: [],
        default_freight: {
          first_fee: '',
          additional_fee: '',
          first_amount: '',
          additional_amount: '',
        },
        AreaList: [],
      },
      alreadyTotalCity: [],
      firstO: [
        {
          value: '0',
          label: '按金额',
        },
        {
          value: '1',
          label: '按件数',
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: '请填写运费模板名称',
            trigger: 'blur',
          },
        ],
        preferential_num: [
          {
            required: true,
            message: '请填写包邮满足条件',
            trigger: 'blur',
          },
        ],
        'default_freight.first_fee': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        'default_freight.additional_fee': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        'default_freight.first_amount': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        'default_freight.additional_amount': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        freight_num: [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    let freightInfo = JSON.parse(localStorage.getItem('freightInfo'));
    let id = this.$route.query.id;
    if (id) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      this.freightInfo = freightInfo;
      this.handleFreightInfo();
    }
  },
  methods: {
    closeCity() {
      this.showCity = !1;
    },
    //处理运费编辑所需要的信息
    handleFreightInfo() {
      let freightInfo = this.freightInfo;
      let form = this.form;
      let AreaList = form.AreaList;
      let areas_infos = freightInfo.areas_infos || [];
      if (areas_infos) areas_infos.splice(0, 1);
      form.name = freightInfo.name;
      form.displayorder = freightInfo.displayorder || '';
      form.pay_type = freightInfo.pay_type;
      form.preferential_num = freightInfo.preferential_num || '';
      form.preferential_type = String(freightInfo.preferential_type);
      form.free_shipping = freightInfo.free_shipping;
      form.is_select = freightInfo.is_select;
      form.is_able = freightInfo.is_able;
      form.default_freight = freightInfo.default_freight;
      form.id = freightInfo.id;
      form.is_select ? (form.is_select = 1) : (form.is_select = 0);
      for (let i in areas_infos) {
        let _child = [];
        let name = '';
        let obj = {
          first_fee: areas_infos[i].first_fee,
          additional_fee: areas_infos[i].additional_fee,
          first_amount: areas_infos[i].first_amount,
          additional_amount: areas_infos[i].additional_amount,
          area_ids: areas_infos[i].area_ids,
          ids: [],
        };
        if (areas_infos[i].province_detail) this.alreadyTotalCity.push(...areas_infos[i].province_detail);
        _child.push(...areas_infos[i].area_detail);
        for (let y in _child) {
          name += _child[y].name + ';';
        }
        areas_infos[i]._child = _child;
        areas_infos[i].form = obj;
        areas_infos[i].area = [];
        areas_infos[i].name = name;
        areas_infos[i].is_open = !0;
      }
      AreaList.push(...areas_infos);
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          let AreaList = form.AreaList;
          let url = form.id ? this.$api.set.freightEdit : this.$api.set.freightAdd;
          form.areas = [];
          for (let i in AreaList) form.areas.push(AreaList[i].form);
          for (let i in form.areas) {
            for (let y in AreaList) {
              let area = AreaList[y].area;
              let _child = AreaList[y]._child;
              if (i == y) {
                if (area.length) {
                  for (let k in area) {
                    form.areas[i].area_ids.push(area[k].id);
                    form.areas[i].area_ids = [...new Set(form.areas[i].area_ids)];
                  }
                }
                for (let k in _child) {
                  form.areas[i].ids.push(_child[k].id);
                  form.areas[i].ids = [...new Set(form.areas[i].ids)];
                }
              }
            }
          }
          if (form.free_shipping != 2) delete form.preferential_num;
          delete form.AreaList;
          this.$axios.post(url, form).then(res => {
            if (res.code == 0) {
              localStorage.removeItem('freightInfo');
              this.$message.success(`${form.id ? '编辑成功' : '添加成功'}`);
              this.$router.push('/set/freightList');
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
              });
            }
          });
        }
      });
    },
    //删除地址
    removeCity(index) {
      let area = this.form.AreaList[index].area;
      let alreadyTotalCity = this.alreadyTotalCity;
      for (let i in area) {
        for (let y in alreadyTotalCity) {
          if (alreadyTotalCity[y].id == area[i].id) alreadyTotalCity.splice(y, 1);
        }
      }
      this.form.AreaList.splice(index, 1);
    },
    getArea(val) {
      if (val.length) {
        this.alreadyTotalCity.push(...val);
        let arr = [];
        let obj = {
          form: {
            first_fee: '',
            additional_fee: '',
            first_amount: '',
            additional_amount: '',
            area_ids: [],
            ids: [],
          },
          area: val,
          name: '',
          is_open: !0,
          _child: [],
        };
        arr.push(obj);
        for (let i in arr) {
          for (let y in arr[i].area) {
            arr[i].area[y]._child = arr[i].area[y]._child || [];
            if (arr[i].area[y].name == '台湾省') arr[i].name += arr[i].area[y].name + ';';
            let _child = arr[i].area[y]._child.filter(item => item.checked);
            for (let c in _child) {
              arr[i].name += _child[c].name + ';';
              arr[i]._child.push(_child[c]);
            }
          }
        }
        this.form.AreaList.push(...arr);
      }
    },
  },
};
